<template>
    <div>
      <b-modal
            :ref="`my-modal`"
            centered
            ok-only
            ok-title="حسنا"
            cancel-variant="outline-secondary"
            title-tag="div"
          >
          <template #title>
    <!-- Your title content goes here -->
    <h3>سياسة </h3>
  </template>
  <b-row>


<b-col md="3" class="mb-md-0 mb-2">
<h3>الموظف: </h3>
<p>{{data.created_by}}</p>
</b-col>
    <b-col md="3" class="mb-md-0 mb-2">
  <h3>العنوان: </h3>
  <p>{{data.title}}</p>
</b-col>
  </b-row>
  <b-row>
    <b-col>
    <h3>الوصف: </h3>
    
  <p>{{data.description}}</p>
  </b-col>
  </b-row>
            <!-- </b-card-code> -->
          </b-modal>
          <b-card-code :title="data.title">

  
<b-row>

<b-col md="3" class="mb-md-0 mb-2">
<h3>الموظف: </h3>
<p>{{data.created_by}}</p>
</b-col>
    <b-col md="3" class="mb-md-0 mb-2">
  <h3>النوع: </h3>
  <p>{{data.type.name}}</p>
</b-col>
  </b-row>
  <b-row>
    <b-col>
    <h3>الوصف: </h3>
    <br />
  <p>{{data.description}}</p>
  </b-col>
  </b-row>
  </b-card-code>
<b-row>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
</b-row>
  
    
    </div>
  </template>
  
  <script>
  import 'vue-good-table/dist/vue-good-table.css'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { VueGoodTable } from 'vue-good-table'
  import vSelect from 'vue-select'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import useList from "./List";
  import {
    BRow,
  
    BCol,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
  
    BFormDatepicker,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BForm,
    BFormInvalidFeedback,
    BPagination,
    BTable,
    BCardHeader, 
    BCardBody,
    BCard,
  } from 'bootstrap-vue'
  
  import flatPickr from 'vue-flatpickr-component'
  import 'flatpickr/dist/flatpickr.css'
  import { quillEditor } from "vue-quill-editor";
  export default {
    name: 'AddSession',
    components: {
      flatPickr,
      BDropdown,
  BCard,
      ToastificationContent,
      BDropdownItem,
      BDropdownItemButton,
      BFormInvalidFeedback,
      VueGoodTable,
      BForm,
      ValidationObserver,
      ValidationProvider,
  
      BCardCode,
      BButton,
      BFormInput,
      BFormFile,
      BFormGroup,
      BInputGroupPrepend,
      BInputGroup,
      BFormDatepicker,
  
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BRow,
      BCol,
      BPagination,
    BTable,
    BCardHeader, 
    BCardBody,
    },
  
    setup(){
    const {
      search,
      typeFilter,
        tableColumns,
        perPage,
        currentPage,
        total,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        fetchData,
        
  
        // Extra Filters
      } = useList()
  
      
      
      return {
        fetchData,
        search,
        filterOn: [],
        searchTerm: "",
        tableColumns,
        perPage,
        currentPage,
        total,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        typeFilter
      }
  
  },
  
    data() {
      return {

  data:{},
      };
  
     
    },
  
  
  
    created() {
this.get()
this.readPolicy()  
    },
  
    methods: {
readPolicy() {
    let url=`/api/v1/get-user-policy/${this.$route.params.id}`
       this.$http.get(url)
    //    .then((res) => {
    //       this.$swal({
    //         title: 'تمت قراءة السياسة    ',

    //         icon: 'success',
    //         confirmButtonText: 'موافق',
    //         customClass: {
    //           confirmButton: 'btn btn-primary',
    //         },
    //         buttonsStyling: false,
    //       })
     

    //     })
},

      showModal(data) {
        //console.log(data);
        // this.data = data;
        this.$refs["my-modal"].show();
      },
      showEmpModal(data) {
        //console.log(data);
        this.EmployeeData = data;
        this.$refs["my-modal-emp"].show();
      },
      get() {
      this.options = [];
      this.$http.get(`/api/v1/policy/${this.$route.params.id}`).then((res) => {
        console.log(res.data.data);
this.data = res.data.data
// this.showModal()
        // this.title = res.data.data.title;
        // this.description=res.data.data.description;
        // this.type=res.data.data.type;
        // this.department = res.data.data.department
      });
    },

  

  
     },
  }
  </script>
  
  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }
  
  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>
  